@import '@aurora/shared-client/styles/_variables.pcss';

.lia-flex-grid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.lia-filter-prefix-text {
  font-weight: var(--lia-font-weight-md);
  font-size: var(--lia-bs-font-size-sm);
  line-height: var(--lia-bs-font-size-sm);
  padding: 5px 0;
  margin-right: 5px;
}
